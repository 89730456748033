"use client";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Image from "next/image";
import Link from "next/link";
import Carousel from "@/components/app/auth/Carousel";
import { auth } from "@/common/utils/firebaseConfig";
import authQueries from "@/lib/api/auth";
import Loader from "@/components/app/Loader";
import { storeToken } from "@/lib/helper";

export default function Login() {
  const { mutate, isPending } = authQueries.SocialAuth();

  const googleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      storeToken("authToken", idToken);
      mutate({ provider: "Google", authToken: idToken });
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  if (isPending) {
    return <Loader loading={isPending} />;
  }

  return (
    <main className="flex flex-col lg:flex-row min-h-screen w-full">
      <div className="basis-full sm:basis-2/4 flex justify-center items-center bg-secondary px-5 lg:px-0 pb-10 lg:pb-0">
        <div className="bg-white px-8 lg:px-12 pb-8 pt-14 border-[0.5px] border-border rounded-2xl h-auto mt-10 lg:mt-0">
          <h1 className="text-primary-text font-semibold text-xl sm:text-2xl text-center">
            Get The Healthcare You Need Now
          </h1>
          <div className="flex flex-col gap-5 mt-8">
            <button
              onClick={googleLogin}
              className="bg-white text-[#8696BB] hover:bg-primary hover:text-white flex flex-row align-middle gap-5 shadow-lg rounded-lg py-3 px-8 cursor-pointer"
            >
              <Image
                src="/assets/icons/auth/google.svg"
                alt="google icon"
                width={30}
                height={30}
              />
              <p className="text-sm sm:text-lg mt-1">Sign Up With Google</p>
            </button>
            <button className="bg-white text-[#8696BB] hover:bg-primary hover:text-white flex flex-row align-middle gap-5 shadow-lg rounded-lg py-3 px-8 cursor-pointer">
              <Image
                src="/assets/icons/auth/apple.svg"
                alt="apple icon"
                width={25}
                height={25}
              />
              <p className="text-sm sm:text-lg mt-1">Sign Up With Apple</p>
            </button>
          </div>
          <div className="flex flex-row my-10 gap-2 align-middle">
            <div className="border-t-2 border-[#C8D3F9] w-full mt-3" />
            <p className="text-base sm:text-lg text-[#8696BB] text-center">
              OR
            </p>
            <div className="border-t-2 border-[#C8D3F9] w-full mt-3" />
          </div>
          <Link legacyBehavior href="/register">
            <button className="bg-primary rounded-lg text-white font-semibold text-center text-base sm:text-xl py-3 w-full">
              Sign Up With Email manually
            </button>
          </Link>
          <div className="my-8">
            <p className="text-center text-sm sm:text-lg font-medium text-[#547196]">
              Already have an account ?{" "}
              <span className="text-primary">
                <Link legacyBehavior href="/login">
                  <a>Log in.</a>
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="relative basis-full lg:basis-2/4 w-full lg:w-2/4 bg-primary py-10 lg:py-0 lg:mt-0 px-5 lg:px-10 flex items-center">
        <Carousel />
        {/* circle icon */}
        <div className="w-5 sm:w-20 h-5 sm:h-20 rounded-full bg-white absolute top-24 z-50 opacity-40" />
        <div className="w-10 sm:w-36 h-10 sm:h-36 rounded-full bg-white absolute top-10 right-10 z-50 opacity-40" />
        <div className="w-4 sm:w-8 h-4 sm:h-8 rounded-full bg-white absolute bottom-[21rem] left-44 z-50 opacity-40" />
      </div>
    </main>
  );
}
