"use client";
import React, { FC } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import Image from "next/image";

const Carousel: FC = () => {
  const swiperDetails = [
    {
      id: 1,
      image: "/assets/images/onboarding/onboarding-1.png",
      title: "The Best Doctors At Your Fingertips",
      alt: "group of doctors",
      body: "Either as a doctor, pharmacy or laboratory, you get to earn income on the side from wherever you are in the world",
      width: 500,
      height: 350,
    },
    {
      id: 2,
      image: "/assets/images/onboarding/onboarding-2.png",
      title: "Offer live checkups and diagnosis to patients",
      alt: "Offer live checkups and diagnosis to patients",
      body: "Easily offer treatmments and diagnosis to patients without a need for them to step into the hospital premises",
      width: 470,
      height: 360,
    },
    {
      id: 3,
      image: "/assets/images/onboarding/onboarding-3.png",
      title: "Give Medical Reports and Prescriptions",
      alt: "doctor with a pill",
      body: "Give Instant reports and prescribe medication for your patients and get it delivered to them",
      width: 580,
      height: 380,
    },
  ];

  return (
    <div className="w-full relative">
      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {swiperDetails.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="flex flex-col text-center my-10 justify-center items-center text-white">
              <div className="mx-auto">
                <Image
                  src={item.image}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                />
              </div>
              <p className="font-semibold text-xl sm:text-2xl mt-5">
                {item.title}
              </p>
              <p className="font-medium text-base sm:text-xl mt-3 mb-10">
                {item.body}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
