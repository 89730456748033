import axios, { AxiosInstance } from "axios";
import { retrieveToken } from "./helper";

// Define an enum for the API categories
enum ApiCategory {
  MAIN,
  BLOCKCHAIN,
}

// Create a function to get the appropriate base URL
const getBaseUrl = (category: ApiCategory): string => {
  switch (category) {
    case ApiCategory.MAIN:
      return process.env.NEXT_PUBLIC_API_BASE_URL || "";
    case ApiCategory.BLOCKCHAIN:
      return process.env.NEXT_PUBLIC_BLOCKCHAIN_API_BASE_URL || "";
    default:
      return process.env.NEXT_PUBLIC_API_BASE_URL || "";
  }
};

// Create a function to generate an axios instance with the appropriate base URL
const createAxiosInstance = (category: ApiCategory): AxiosInstance => {
  const instance = axios.create({
    baseURL: getBaseUrl(category),
  });

  instance.interceptors.request.use(
    (config: any) => {
      if (typeof window !== "undefined") {
        const token = retrieveToken("authToken");

        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
        }
      }
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (typeof window !== "undefined") {
        if (error.response?.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

// Export functions to get specific axios instances
export const getMainApiInstance = () => createAxiosInstance(ApiCategory.MAIN);
export const getBlockchainApiInstance = () =>
  createAxiosInstance(ApiCategory.BLOCKCHAIN);

// You can also export a default instance if needed
export default createAxiosInstance(ApiCategory.MAIN);
